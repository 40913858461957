/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://mvwxwhlydjha5dqmng2dphybnm.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mr6zze3zpvbjvdpc5x5ugw4b24",
    "aws_cognito_identity_pool_id": "us-east-2:7ef9afc8-5878-4743-85b7-22013adaead0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_8oCpPKjX9",
    "aws_user_pools_web_client_id": "3762ojvognjco6nkbp70lg11j6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyfullstack784a08db989a44999eeced199ed6d7214033-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
